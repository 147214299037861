import { getMaps } from "./maps.js";
import { ResponsiveImg } from "../premmio/public/components/responsiveImg/responsiveImg.html.js";

// rotate the map backgrounds
const changeMapBackground = (category) => {
  const mapCategories = document.querySelector("#map-categories"),
    categoryMaps = mapCategories.querySelector(`.category.${category}`),
    currentBackground = parseInt(
      mapCategories.getAttribute(`data-active-${category}`)
    );

  // if less than 5, increment, otherwise reset to 1
  if (currentBackground < categoryMaps.children.length) {
    mapCategories.setAttribute(
      `data-active-${category}`,
      currentBackground + 1
    );
  } else {
    mapCategories.setAttribute(`data-active-${category}`, 1);
  }
};

setInterval(() => {
  changeMapBackground("fantasy");
}, 4500);

setTimeout(() => {
  setInterval(() => {
    changeMapBackground("cyberpunk");
  }, 5000);
}, 2500);

getMaps("fantasy-maps", 6, (maps) => {
  const latestFantasy = document.querySelector("#latest-fantasy");

  maps.forEach((map) => {
    const mapElement = new ResponsiveImg({
      ...map.image,
      class: "mapSquare",
    });

    Materia.render(mapElement, (map) => {
      latestFantasy.appendChild(map);
    });
  });
});

getMaps("cyberpunk-maps", 6, (maps) => {
  const latestSciFi = document.querySelector("#latest-cyberpunk");

  maps.forEach((map) => {
    const mapElement = new ResponsiveImg({
      ...map.image,
      class: "mapSquare",
    });

    Materia.render(mapElement, (map) => {
      latestSciFi.appendChild(map);
    });
  });
});
